import React, { Component } from "react";

import data from "../helpers/data";
import "../css/Contestants.scss";
import bell from "../assets/bell.mp3";

class Contestants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playerOne: {},
      playerTwo: {},
      bell: new Audio(bell)
    };

    this.getPlayers = this.getPlayers.bind(this);
  }

  componentWillMount() {
    this.getPlayers();
  }

  componentDidMount() {
    this.state.bell.play();
  }

  getPlayers() {
    let p1 = data.participants.filter(
      p => p.id === +this.props.match.params.playerone
    );
    let p2 = data.participants.filter(
      p => p.id === +this.props.match.params.playertwo
    );

    this.setState({
      playerOne: p1[0],
      playerTwo: p2[0]
    });
  }

  render() {
    return (
      <div className="contestants">
        <div>
          <div className="column lg">
            <img
              src={
                this.state.playerOne.image
                  ? require(`../assets/players/${
                      this.state.playerOne.image
                    }.png`)
                  : `https://api.adorable.io/avatars/285/${this.state.playerOne.name
                      .replace(" ", "-")
                      .toLowerCase()}`
              }
              alt={this.state.playerOne.name}
            />
          </div>
          <div className="column sm">
            <h3>VS</h3>
          </div>
          <div className="column lg">
            <img
              src={
                this.state.playerTwo.image
                  ? require(`../assets/players/${
                      this.state.playerTwo.image
                    }.png`)
                  : `https://api.adorable.io/avatars/285/${this.state.playerTwo.name
                      .replace(" ", "-")
                      .toLowerCase()}`
              }
              alt={this.state.playerTwo.name}
            />
          </div>
        </div>
        <div>
          <div className="column full">
            <iframe
              title="youtube"
              width="800"
              height="500"
              src="https://www.youtube.com/embed/pwAwrYl6wjw?controls=0&autoplay=1&loop=1&rel=0&showinfo=0&modestbranding=1&controls=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>
        </div>
        <div>
          <a
            className="start"
            href={`/result/${this.state.playerOne.id}/${
              this.state.playerTwo.id
            }`}
          >
            Fin
          </a>
        </div>
      </div>
    );
  }
}

export default Contestants;
