import React, { Component } from "react";

import data from "../helpers/data";
import PlayerCard from "../components/PlayerCard";

import "../css/Play.scss";

class Play extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playerOne: {},
      playerTwo: {},
      play: false,
      pause: true
    };

    this.getPlayers = this.getPlayers.bind(this);
  }

  componentWillMount() {
    this.getPlayers();
  }
  getPlayers() {
    let p1 = data.participants.filter(
      p => p.id === +this.props.match.params.playerone
    );
    let p2 = data.participants.filter(
      p => p.id === +this.props.match.params.playertwo
    );

    this.setState({
      playerOne: p1[0],
      playerTwo: p2[0]
    });
  }

  render() {
    return (
      <div className="play">
        <div>
          <div className="column lg">
            <PlayerCard
              playerName={this.state.playerOne.name}
              skill={this.state.playerOne.skill}
              rating={this.state.playerOne.rating}
              image={this.state.playerOne.image}
            />
          </div>
          <div className="column sm">
            <h3>VS</h3>
          </div>
          <div className="column lg">
            <PlayerCard
              playerName={this.state.playerTwo.name}
              skill={this.state.playerTwo.skill}
              rating={this.state.playerTwo.rating}
              image={this.state.playerTwo.image}
            />
          </div>
        </div>
        <div>
          <a
            className="start"
            href={`/contestants/${this.state.playerOne.id}/${
              this.state.playerTwo.id
            }`}
          >
            Let's play
          </a>
        </div>
      </div>
    );
  }
}

export default Play;
