import React, { Component } from "react";
import "../css/App.scss";
import Select from "../components/Select";

import data from "../helpers/data";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      participants: [],
      playerOne: 0,
      playerTwo: 0
    };

    this.getAllParticipants = this.getAllParticipants.bind(this);
    this.handlePlayerOneChange = this.handlePlayerOneChange.bind(this);
    this.handlePlayerTwoChange = this.handlePlayerTwoChange.bind(this);
  }

  componentWillMount() {
    this.getAllParticipants();
  }

  getAllParticipants() {
    this.setState({
      participants: data.participants.sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    });
  }

  handlePlayerOneChange(data) {
    this.setState({
      playerOne: +data.target.value
    });
  }

  handlePlayerTwoChange(data) {
    this.setState({
      playerTwo: +data.target.value
    });
  }

  render() {
    return (
      <div className="home">
        <div className="balls" />
        <div>
          <div className="column lg">
            <h2>PING</h2>
          </div>
          <div className="column sm" />
          <div className="column lg">
            <h2>PONG</h2>
          </div>
        </div>
        <div>
          <div className="column lg">
            <Select
              data={this.state.participants}
              onChange={this.handlePlayerOneChange}
            />
          </div>
          <div className="column sm">
            <h3>VS</h3>
          </div>
          <div className="column lg">
            <Select
              data={this.state.participants}
              onChange={this.handlePlayerTwoChange}
            />
          </div>
        </div>
        <div>
          <a
            className="start"
            href={`/play/${this.state.playerOne}/${this.state.playerTwo}`}
          >
            DO THE THING
          </a>
        </div>
      </div>
    );
  }
}

export default Home;
