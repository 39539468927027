import React, { Component } from "react";

import data from "../helpers/data";
import "../css/Result.scss";

import confetti1 from "../assets/confetti-1.png";
import confetti2 from "../assets/confetti-2.png";
import confetti3 from "../assets/confetti-3.png";
import confettidots from "../assets/confetti-dots.png";
import angry from "../assets/angry.png";

class Result extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playerOne: {},
      playerTwo: {},
      winner: null,
      winningPlayer: {}
    };

    this.getPlayers = this.getPlayers.bind(this);
    this.handleWinner = this.handleWinner.bind(this);
  }

  componentWillMount() {
    this.getPlayers();
  }
  getPlayers() {
    let p1 = data.participants.filter(
      p => p.id === +this.props.match.params.playerone
    );
    let p2 = data.participants.filter(
      p => p.id === +this.props.match.params.playertwo
    );

    this.setState({
      playerOne: p1[0],
      playerTwo: p2[0],
      nextGame: false,
      animate: false
    });
  }

  handleWinner(e) {
    this.setState({
      nextGame: true,
      animate: true,
      winner:
        e.target.dataset.player === "playerOne" ? "playerOne" : "playerTwo",
      winningPlayer:
        e.target.dataset.player === "playerOne"
          ? this.state.playerOne
          : this.state.playerTwo
    });
  }

  render() {
    return (
      <div className="result">
        <div className="confetti">
          <img
            src={confetti1}
            alt="Confetti"
            className={`confetti1 ${this.state.animate && "animate1"}`}
          />
          <img
            src={confetti2}
            alt="Confetti"
            className={`confetti2 ${this.state.animate && "animate2"}`}
          />
          <img
            src={confetti3}
            alt="Confetti"
            className={`confetti3 ${this.state.animate && "animate3"}`}
          />
          <img
            src={confettidots}
            alt="Confetti"
            className={`confettidots ${this.state.animate && "animatedots"}`}
          />
        </div>
        <div>
          {!this.state.nextGame ? <h1>FIN! WHO WON?</h1> : <h1>CONGRATS!</h1>}
          {this.state.nextGame && (
            <h1 className="winning">{this.state.winningPlayer.name}</h1>
          )}
        </div>

        <div className="players">
          <div className="column lg">
            {this.state.nextGame && (
              <img src={angry} alt="Angry emoji" className="angry" />
            )}
            <img
              src={
                this.state.playerOne.image
                  ? require(`../assets/players/${
                      this.state.playerOne.image
                    }.png`)
                  : `https://api.adorable.io/avatars/285/${this.state.playerOne.name
                      .replace(" ", "-")
                      .toLowerCase()}`
              }
              alt={this.state.playerOne.name}
              onClick={this.handleWinner}
              data-player="playerOne"
              className={`player ${this.state.winner === "playerOne" &&
                "winner"} ${this.state.winner !== null &&
                this.state.winner !== "playerOne" &&
                "loser"}`}
            />
          </div>
          {!this.state.nextGame && (
            <div className="column sm">
              <h3>OR</h3>
            </div>
          )}
          <div className="column lg">
            {this.state.nextGame && (
              <img src={angry} alt="Angry emoji" className="angry" />
            )}
            <img
              src={
                this.state.playerTwo.image
                  ? require(`../assets/players/${
                      this.state.playerTwo.image
                    }.png`)
                  : `https://api.adorable.io/avatars/285/${this.state.playerTwo.name
                      .replace(" ", "-")
                      .toLowerCase()}`
              }
              alt={this.state.playerTwo.name}
              data-player="playerTwo"
              onClick={this.handleWinner}
              className={`player ${this.state.winner === "playerTwo" &&
                "winner"} ${this.state.winner !== null &&
                this.state.winner !== "playerTwo" &&
                "loser"}`}
            />
          </div>
        </div>
        {this.state.nextGame && (
          <div>
            <a className="start" href="/">
              Next game
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default Result;
