import React, { Component } from "react";

import "../css/Select.scss";

class Select extends Component {
  render() {
    return (
      <div className="Select">
        <select required onChange={this.props.onChange}>
          <option>Choose a player</option>
          {this.props.data.map(d => {
            return (
              <option key={d.id} value={d.id}>
                {d.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

export default Select;
