import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Play from "./Play";
import Contestants from "./Contestants";
import Result from "./Result";

class Main extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/play/:playerone/:playertwo" component={Play} />
        <Route
          path="/contestants/:playerone/:playertwo"
          component={Contestants}
        />
        <Route path="/result/:playerone/:playertwo" component={Result} />
      </Switch>
    );
  }
}

export default Main;
