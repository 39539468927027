import React, { Component } from "react";

import "../css/PlayerCard.scss";

class PlayerCard extends Component {
  render() {
    return (
      <div className="player">
        <div className="card">
          <img
            src={
              this.props.image
                ? require(`../assets/players/${this.props.image}.png`)
                : `https://api.adorable.io/avatars/285/${this.props.playerName
                    .replace(" ", "-")
                    .toLowerCase()}`
            }
            alt={this.props.name}
          />
          <h1>{this.props.playerName}</h1>
          <h2>Skill:</h2>
          <p>{this.props.skill}</p>
          <h2>Ping Pong level:</h2>
          <p>{this.props.rating}/10</p>
        </div>
      </div>
    );
  }
}

export default PlayerCard;
